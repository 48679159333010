import _objectWithoutProperties from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "multiLocationMenuContainers", "isBlade"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './MultiLocationGrid.style';
import Anchor from '../../atoms/Anchor';
import RichText from '../../atoms/RichText/RichText';
import Heading from '../../atoms/Heading/Heading';
import Para from '../../atoms/Para/Para';
// const slugify = require('url-slug');
import urlSlug from 'url-slug';

// import { YextMockData } from './MultiLocationGrid.mock';
import GridItem from './GridItem';
import { useYextFetch } from '../../../hooks/useYextFetch';
import analyticsConstants from '../../../constants/analyticsConstants';
var MultiLocationGridBlock = styled.section.withConfig({
  componentId: "sc-1jrftqu-0"
})(["", ";"], styles);
var MultiLocationGrid = function MultiLocationGrid(_ref) {
  var _finalSingles, _multiLocationMenuCon, _multiLocationMenuCon2, _multiLocationMenuCon3, _multiLocationMenuCon4;
  var className = _ref.className,
    multiLocationMenuContainers = _ref.multiLocationMenuContainers,
    isBlade = _ref.isBlade,
    others = _objectWithoutProperties(_ref, _excluded);
  // get yext id's from the "single input" field in contentful
  var yextSingleIds = multiLocationMenuContainers && multiLocationMenuContainers.locationContainer && multiLocationMenuContainers.locationContainer.yextIds || '';

  // get yext folder id's from the "folder input" field in contentful
  var yextFolderIds = multiLocationMenuContainers && multiLocationMenuContainers.locationContainer && multiLocationMenuContainers.locationContainer.yextFolderIds || '';
  var gridColumns = multiLocationMenuContainers.gridViewColumnCount && multiLocationMenuContainers.gridViewColumnCount.charAt(0) || '4';
  var isTwoCols = gridColumns && gridColumns == '2' ? true : false;
  // TODO: Fix two columns layout

  // call hook to run yext API and return results

  var finalSingles;
  var finalFolders;
  if (!isBlade) {
    var yextLocationsInformationData =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    yextSingleIds && useYextFetch(yextSingleIds, false, gridColumns);
    var yextFolderInformationData =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    yextFolderIds && useYextFetch(yextFolderIds, true, gridColumns);
    finalSingles = yextSingleIds.length > 1 && yextLocationsInformationData && yextLocationsInformationData.entities && yextLocationsInformationData.entities.slice(0, gridColumns) || yextSingleIds.length == 1 && yextLocationsInformationData && yextLocationsInformationData;
    finalFolders = yextFolderInformationData && yextFolderInformationData.entities && yextFolderInformationData.entities.slice(0, gridColumns - (yextSingleIds.length || 0)); // make total items from both queries equal to gridColumns
  } else {
    var _yextLocationsInformationData =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    yextSingleIds && useYextFetch(yextSingleIds, false);
    var _yextFolderInformationData =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    yextFolderIds && useYextFetch(yextFolderIds, true);
    finalSingles = _yextLocationsInformationData && _yextLocationsInformationData.entities && _yextLocationsInformationData.entities;
    finalFolders = _yextFolderInformationData && _yextFolderInformationData.entities && _yextFolderInformationData.entities;
  }
  var sortedList = finalSingles && Array.isArray(finalSingles) && ((_finalSingles = finalSingles) === null || _finalSingles === void 0 ? void 0 : _finalSingles.sort(function (a, b) {
    return yextSingleIds.indexOf(a.meta.id) - yextSingleIds.indexOf(b.meta.id);
  }));
  return __jsx(MultiLocationGridBlock, {
    multiLocationMenuContainers: multiLocationMenuContainers && multiLocationMenuContainers,
    isTwoCols: isTwoCols,
    gridColumns: gridColumns,
    isBlade: isBlade,
    className: "nva-multi-location-grid"
  }, __jsx("div", {
    style: {
      textAlign: 'center'
    },
    className: "nva-multi-location-grid__header col-md-10 col-md-offset-1 container multi-location-header"
  }, multiLocationMenuContainers && multiLocationMenuContainers.headline && __jsx(Heading, {
    HeadingType: "h2",
    id: "".concat(urlSlug(multiLocationMenuContainers && multiLocationMenuContainers.headline)),
    className: "nva-multi-location-grid__heading"
  }, multiLocationMenuContainers && multiLocationMenuContainers.headline), multiLocationMenuContainers && multiLocationMenuContainers.subHeadline && __jsx(Para, {
    className: "nva-multi-location-grid__sub-headline heading_four"
  }, multiLocationMenuContainers && multiLocationMenuContainers.subHeadline), multiLocationMenuContainers.bodyText && multiLocationMenuContainers.bodyText.json && __jsx("div", {
    className: "nva-multi-location-grid__body-text col-md-10 col-md-offset-1 multi-location-rich-text"
  }, ' ', __jsx(RichText, {
    jsonContent: multiLocationMenuContainers.bodyText.json
  }), ' '), multiLocationMenuContainers && multiLocationMenuContainers.cta && __jsx(Anchor, {
    "data-entry-id": (_multiLocationMenuCon = multiLocationMenuContainers.cta.sys) === null || _multiLocationMenuCon === void 0 ? void 0 : _multiLocationMenuCon.id,
    title: multiLocationMenuContainers.cta.label,
    to: multiLocationMenuContainers.cta.url,
    hyperlinkType: multiLocationMenuContainers.cta.hyperlinkType,
    ctaBehavior: multiLocationMenuContainers.cta.behavior,
    "aria-label": multiLocationMenuContainers.cta.ariaLabel,
    styleType: "primary-anchor",
    type: multiLocationMenuContainers.cta.type,
    className: "nva-multi-location-grid__anchor multi-location-header-btn"
  }, multiLocationMenuContainers.cta.label)), __jsx("div", {
    className: "nva-multi-location-grid__list multi-location-grid"
  }, sortedList && yextSingleIds.length > 1 && sortedList.map(function (item) {
    return __jsx(GridItem, {
      className: "nva-multi-location-grid__list-item",
      key: item.meta.id,
      item: item,
      isTwoCols: isTwoCols,
      gridColumns: gridColumns,
      dataAnalyticsValue: analyticsConstants.multiLocationGrid.address.analyticsValue,
      dataAnalyticsTypeCard: analyticsConstants.multiLocationGrid.address.analyticsTypeCard,
      dataAnalyticsTypeMap: analyticsConstants.multiLocationGrid.address.analyticsTypeMap
    });
  }), finalSingles && finalSingles.meta && __jsx(GridItem, {
    className: "nva-multi-location-grid__list-item",
    key: 'single',
    item: finalSingles,
    isTwoCols: isTwoCols,
    gridColumns: gridColumns,
    dataAnalyticsValue: analyticsConstants.multiLocationGrid.address.analyticsValue,
    dataAnalyticsTypeCard: analyticsConstants.multiLocationGrid.address.analyticsTypeCard,
    dataAnalyticsTypeMap: analyticsConstants.multiLocationGrid.address.analyticsTypeMap
  }), finalFolders && finalFolders.map(function (item) {
    return __jsx(GridItem, {
      className: "nva-multi-location-grid__list-item",
      key: item.meta.id,
      item: item,
      isTwoCols: isTwoCols,
      gridColumns: gridColumns,
      dataAnalyticsValue: analyticsConstants.multiLocationGrid.address.analyticsValue,
      dataAnalyticsTypeCard: analyticsConstants.multiLocationGrid.address.analyticsTypeCard,
      dataAnalyticsTypeMap: analyticsConstants.multiLocationGrid.address.analyticsTypeMap
    });
  })), !isBlade && multiLocationMenuContainers.bladeCta && __jsx("div", {
    className: "multi-location-view-more"
  }, __jsx(Anchor, {
    "data-entry-id": (_multiLocationMenuCon2 = multiLocationMenuContainers.bladeCta) === null || _multiLocationMenuCon2 === void 0 ? void 0 : (_multiLocationMenuCon3 = _multiLocationMenuCon2.sys) === null || _multiLocationMenuCon3 === void 0 ? void 0 : _multiLocationMenuCon3.id,
    title: multiLocationMenuContainers.bladeCta && multiLocationMenuContainers.bladeCta.label || 'View More',
    "aria-label": (_multiLocationMenuCon4 = multiLocationMenuContainers.bladeCta) === null || _multiLocationMenuCon4 === void 0 ? void 0 : _multiLocationMenuCon4.ariaLabel,
    to: multiLocationMenuContainers.bladeCta && multiLocationMenuContainers.bladeCta.url,
    hyperlinkType: multiLocationMenuContainers.bladeCta.hyperlinkType,
    styleType: "primary-anchor",
    type: multiLocationMenuContainers.bladeCta.type,
    className: "nva-multi-location-grid__anchor nav-btn center",
    ctaBehavior: multiLocationMenuContainers.bladeCta && multiLocationMenuContainers.bladeCta.behavior,
    "data-analytics-type": 'Multi-location grid blade CTA',
    "data-analytics-variation": multiLocationMenuContainers.variation,
    "data-analytics-value": multiLocationMenuContainers.dataAnalyticsValue ? multiLocationMenuContainers.dataAnalyticsValue : ''
  }, multiLocationMenuContainers.bladeCta && multiLocationMenuContainers.bladeCta.label || 'View More')));
};
MultiLocationGrid.defaultProps = {
  className: ''
};
export default MultiLocationGrid;