import _objectWithoutProperties from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "globalHeaderData", "yextContactInformationData", "headerLogo", "siteBusinessType", "showContactInfoInFooter", "isNvaMainSite", "themeName"];
var __jsx = React.createElement;
/**
 * Represents Global Header for GeneralPractice business type.
 * @author gsin24
 */import React from 'react';
import styled from 'styled-components';
import styles from './GeneralPracticeHeader.style';
import Header from '../../../molecules/Header';
// import  { useAllYextMultiData }  from "../../../../hooks/useAllYextMultiData"

var GeneralPracticeHeader = function GeneralPracticeHeader(_ref) {
  var className = _ref.className,
    globalHeaderData = _ref.globalHeaderData,
    yextContactInformationData = _ref.yextContactInformationData,
    headerLogo = _ref.headerLogo,
    siteBusinessType = _ref.siteBusinessType,
    showContactInfoInFooter = _ref.showContactInfoInFooter,
    isNvaMainSite = _ref.isNvaMainSite,
    themeName = _ref.themeName,
    others = _objectWithoutProperties(_ref, _excluded);
  if (!globalHeaderData) {
    return null;
  }
  var imageDetails = {
    headerLogo: headerLogo
  };

  // const { nodes } = useAllYextMultiData();

  return __jsx(Header, {
    className: "nva-global-header__general-practice"
    // yextMultiLocationdata={nodes}
    ,
    globalHeaderData: globalHeaderData
    // yextData={yextData}
    ,
    yextContactInformationData: yextContactInformationData && yextContactInformationData,
    imageDetails: imageDetails,
    siteBusinessType: siteBusinessType,
    showContactInfoInFooter: showContactInfoInFooter,
    themeName: themeName,
    isNvaMainSite: isNvaMainSite
  });
};
GeneralPracticeHeader.defaultProps = {
  className: '',
  isNvaMainSite: false
};
export default styled(GeneralPracticeHeader).withConfig({
  componentId: "sc-4pfnbf-0"
})(["", ";"], styles);
export { GeneralPracticeHeader as GeneralPracticeHeaderVanilla };