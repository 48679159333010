import _defineProperty from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import React from 'react';
import styled from 'styled-components';
import styles from './PencilBanner.style';
import RichText from '../../atoms/RichText/RichText';
var PencilBanner = function PencilBanner(_ref) {
  var bannerData = _ref.bannerData,
    className = _ref.className;
  var message = bannerData.message;
  var bannerMessage = message ? _objectSpread({}, message.json) : '';
  var anchorLink = bannerData && (bannerData === null || bannerData === void 0 ? void 0 : bannerData.anchorLink) || '';
  //Make sure text is not over max length
  if (bannerMessage != '') {
    bannerMessage = _objectSpread(_objectSpread({}, bannerMessage), {}, {
      content: [_objectSpread(_objectSpread({}, bannerMessage.content[0]), {}, {
        content: [_objectSpread(_objectSpread({}, bannerMessage.content[0].content[0]), {}, {
          value: bannerMessage.content[0].content[0].value.substring(0, 256)
        })]
      })]
    });
  }
  return __jsx("div", {
    className: "nva-pencil-banner ".concat(className),
    id: anchorLink
  }, __jsx(RichText, {
    ContainerElem: "div",
    jsonContent: bannerMessage,
    className: "nva-pencil-banner__message container"
  }));
};
PencilBanner.defaultProps = {
  className: ''
};
export default styled(PencilBanner).withConfig({
  componentId: "sc-143ytcg-0"
})(["", ";"], styles);