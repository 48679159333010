import _toConsumableArray from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
var __jsx = React.createElement;
/**
 * Represents Location Finder.
 * @author shivam
 */import React from 'react';
import LocationFinder from '../../molecules/LocationFinder';
import { useYextFetch } from '../../../hooks/useYextFetch';
var LocationFinderForm = function LocationFinderForm(_ref) {
  var _multiLocationMenuCon, _multiLocationMenuCon2;
  var yextSearchApi = _ref.yextSearchApi,
    nvaSite = _ref.nvaSite,
    multiLocationMenuContainers = _ref.multiLocationMenuContainers;
  var yextSingleIds = multiLocationMenuContainers && (multiLocationMenuContainers === null || multiLocationMenuContainers === void 0 ? void 0 : multiLocationMenuContainers.locationContainer) && (multiLocationMenuContainers === null || multiLocationMenuContainers === void 0 ? void 0 : (_multiLocationMenuCon = multiLocationMenuContainers.locationContainer) === null || _multiLocationMenuCon === void 0 ? void 0 : _multiLocationMenuCon.yextIds) || '';

  // get yext folder id's from the "folder input" field in contentful
  var yextFolderIds = multiLocationMenuContainers && (multiLocationMenuContainers === null || multiLocationMenuContainers === void 0 ? void 0 : multiLocationMenuContainers.locationContainer) && (multiLocationMenuContainers === null || multiLocationMenuContainers === void 0 ? void 0 : (_multiLocationMenuCon2 = multiLocationMenuContainers.locationContainer) === null || _multiLocationMenuCon2 === void 0 ? void 0 : _multiLocationMenuCon2.yextFolderIds) || '';

  // call hook to run yext API and return results
  var yextLocationsInformationData =
  // eslint-disable-next-line react-hooks/rules-of-hooks
  yextSingleIds && useYextFetch(yextSingleIds, false);
  var yextFolderInformationData =
  // eslint-disable-next-line react-hooks/rules-of-hooks
  yextFolderIds && useYextFetch(yextFolderIds, true);
  var allLocations = [];
  yextLocationsInformationData && (yextLocationsInformationData === null || yextLocationsInformationData === void 0 ? void 0 : yextLocationsInformationData.entities) && allLocations.push.apply(allLocations, _toConsumableArray(yextLocationsInformationData === null || yextLocationsInformationData === void 0 ? void 0 : yextLocationsInformationData.entities));
  yextFolderInformationData && yextFolderInformationData !== 'no data' && (yextFolderInformationData === null || yextFolderInformationData === void 0 ? void 0 : yextFolderInformationData.entities) && allLocations.push.apply(allLocations, _toConsumableArray(yextFolderInformationData === null || yextFolderInformationData === void 0 ? void 0 : yextFolderInformationData.entities));
  return __jsx(LocationFinder, {
    yextLocationsInformationData: allLocations,
    multiLocationMenuContainers: multiLocationMenuContainers
  });
};
export default LocationFinderForm;