import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function Cart(props) {
  return __jsx("svg", _extends({
    width: 475.084,
    height: 475.085,
    viewBox: "0 0 475.084 475.085"
  }, props), __jsx("path", {
    d: "M365.446 401.998c0 10.092 3.579 18.702 10.711 25.834 7.132 7.139 15.749 10.711 25.845 10.711 10.081 0 18.698-3.572 25.83-10.711 7.139-7.132 10.711-15.742 10.711-25.834s-3.568-18.702-10.711-25.841c-7.132-7.132-15.749-10.704-25.83-10.704-10.096 0-18.713 3.572-25.845 10.704-7.132 7.139-10.711 15.749-10.711 25.841zM469.658 78.51c-3.618-3.617-7.898-5.426-12.848-5.426H113.918c-.193-1.331-.621-3.756-1.287-7.277-.666-3.523-1.188-6.329-1.569-8.425-.383-2.087-1.093-4.611-2.142-7.561-1.047-2.952-2.284-5.286-3.711-6.995-1.425-1.718-3.328-3.189-5.708-4.43-2.378-1.233-5.092-1.853-8.136-1.853H18.276c-4.952 0-9.234 1.812-12.85 5.424C1.809 45.583 0 49.868 0 54.816s1.809 9.231 5.426 12.847c3.619 3.617 7.902 5.424 12.85 5.424h58.237l50.532 234.976c-.378.76-2.329 4.373-5.852 10.848-3.521 6.475-6.328 12.135-8.42 16.988-2.093 4.859-3.14 8.616-3.14 11.279 0 4.948 1.809 9.232 5.424 12.854 3.621 3.606 7.902 5.421 12.851 5.421h292.348c4.948 0 9.232-1.814 12.847-5.421 3.62-3.621 5.427-7.905 5.427-12.854 0-4.949-1.807-9.233-5.427-12.847-3.614-3.614-7.898-5.428-12.847-5.428h-262.66c4.57-9.138 6.854-15.222 6.854-18.268 0-1.909-.238-4.004-.715-6.283s-1.047-4.805-1.713-7.569c-.667-2.752-1.093-4.799-1.283-6.133l298.077-34.831c4.753-.575 8.658-2.614 11.703-6.14 3.046-3.518 4.565-7.562 4.565-12.133V91.363c-.002-4.948-1.806-9.231-5.426-12.853zM109.632 401.998c0 10.092 3.567 18.702 10.706 25.834 7.141 7.139 15.75 10.711 25.841 10.711 10.085 0 18.699-3.572 25.835-10.711 7.139-7.132 10.71-15.742 10.71-25.834s-3.568-18.702-10.71-25.841c-7.137-7.132-15.75-10.704-25.835-10.704-10.09 0-18.704 3.572-25.841 10.704-7.135 7.139-10.706 15.749-10.706 25.841z"
  }));
}
export default Cart;