import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function Calendar(props) {
  return __jsx("svg", _extends({
    viewBox: "0 0 16.105 15.8"
  }, props), __jsx("g", {
    id: "prefix__Calendar",
    transform: "translate(-127.114 -88)"
  }, __jsx("path", {
    id: "prefix__Path_170",
    "data-name": "Path 170",
    className: "prefix__cls-1",
    d: "M15.961 12.4h-.627v.831a1.373 1.373 0 01-2.746 0V12.4H8.5v.831a1.373 1.373 0 01-2.746 0V12.4h-.626A2.641 2.641 0 002.5 15.028v9a2.645 2.645 0 002.628 2.645h10.833a2.648 2.648 0 002.645-2.645v-9a2.645 2.645 0 00-2.645-2.628zm1.271 11.63a1.27 1.27 0 01-1.271 1.27H5.128a1.255 1.255 0 01-1.255-1.27v-6.146h13.359z",
    transform: "translate(124.614 77.126)"
  }), __jsx("path", {
    id: "prefix__Path_171",
    "data-name": "Path 171",
    className: "prefix__cls-1",
    d: "M26.4 6.435a.7.7 0 00.7-.7V4.1a.7.7 0 00-.7-.7.687.687 0 00-.7.7v1.639a.709.709 0 00.7.696z",
    transform: "translate(105.347 84.6)"
  }), __jsx("path", {
    id: "prefix__Path_172",
    "data-name": "Path 172",
    className: "prefix__cls-1",
    d: "M66.8 6.435a.7.7 0 00.7-.7V4.1a.7.7 0 00-.7-.7.687.687 0 00-.7.7v1.639a.687.687 0 00.7.696z",
    transform: "translate(71.796 84.6)"
  })));
}
export default Calendar;